<script setup lang="ts">
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import type { UseBreakpointsReturn } from '@vueuse/core';

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const { isLoggedIn } = useUser();
const localePath = useLocalePath();
const menuWrapper = ref<HTMLDivElement>();
const headerWrapper = ref<HTMLDivElement>();

const fastOrderComponent = computed(() => {
    if (isLoggedIn.value) return resolveComponent('NuxtLink');
    return 'button';
});

const { headerVisible } = useHeaderState();

const breakpoints: UseBreakpointsReturn = inject('breakpoints') as UseBreakpointsReturn;
const isDesktop = breakpoints.greaterOrEqual('lg');

const { product } = usePage();

// fully hide header on desktop pdp
const hideHeader = computed(() => !headerVisible.value && product.value && isDesktop.value);
const isScrolledDown = ref(false);
// hide header top part on mobile pdp and if user scrolled down
const hideHeaderTopPart = computed(() => isScrolledDown.value && !!product.value && !isDesktop.value);

const { setHeaderHeight } = useHeaderState();

const HIDE_HEADER_ANIMATION_DURATION = 150;

if (import.meta.client) {
    const { arrivedState } = useScroll(window);

    watch(
        () => arrivedState.top,
        (arrivedTop) => {
            isScrolledDown.value = !arrivedTop;

            // wait for hideHeaderTopPart animation
            setTimeout(() => {
                setHeaderHeight(headerWrapper.value?.clientHeight);
            }, HIDE_HEADER_ANIMATION_DURATION);
        },
    );

    useEventListener(
        'resize',
        () => {
            setHeaderHeight(headerWrapper.value?.clientHeight);
        },
        { passive: true },
    );

    watch(
        headerWrapper,
        () => {
            setHeaderHeight(headerWrapper.value?.clientHeight);
        },
        { once: true },
    );
}

const { trackClickOnQuickOrder } = useQuickOrderTracking();
const quickOrderClicked = () => {
    if (!isLoggedIn.value) {
        offcanvas.open('AccountLoginForm', 'layout-login-offcanvas');
    } else {
        trackClickOnQuickOrder();
    }
};
</script>

<template>
    <div
        ref="headerWrapper"
        class="shadow top-0 z-10"
        :class="{
            sticky: !hideHeader,
        }"
    >
        <Transition
            enterActiveClass="transition-all ease-in-out"
            enterFromClass="-translate-y-20 h-0"
            enterToClass="translate-y-0"
            leaveActiveClass="transition-all ease-in-out"
            leaveFromClass="translate-y-0"
            leaveToClass="-translate-y-20 h-0"
        >
            <div
                v-show="!hideHeaderTopPart"
                class="relative bg-white"
            >
                <div class="container flex justify-between py-2.5 pr-0 xl:pr-5 lg:py-3.5">
                    <div class="flex flex-wrap items-center">
                        <NuxtLink
                            :to="localePath('/')"
                            class="flex flex-wrap relative"
                            :title="$t('home')"
                            :aria-label="$t('home')"
                        >
                            <SvgoLogoAhSmall class="text-brand-primary h-11 lg:hidden" />

                            <SvgoLogoAhClaim
                                class="text-brand-primary h-14 hidden lg:block"
                                height="56"
                            />
                        </NuxtLink>
                    </div>

                    <LayoutStoreSearch type="desktop" />

                    <div class="flex flex-wrap items-center">
                        <LayoutLanguageSelector />

                        <button
                            key="serviceMenuClient"
                            class="bg-transparent py-3 px-5.5 md:px-3.5 relative group cursor-pointer"
                            :title="$t('appMenu')"
                            :aria-label="$t('appMenu')"
                            @click="offcanvas.open('AccountAppMenu', 'layout-header-account-app-menu')"
                        >
                            <SvgoGridGear class="w-auto h-6" />
                            <span
                                class="absolute text-sm hidden lg:group-hover:block -bottom-3 left-1/2 translate-x-[-50%] whitespace-nowrap"
                            >
                                {{ $t('appMenu') }}
                            </span>
                        </button>

                        <SharedOffcanvasContent
                            id="layout-header-account-app-menu"
                            :offcanvas="offcanvas"
                        />

                        <component
                            :is="fastOrderComponent"
                            key="fastOrderClient"
                            :to="isLoggedIn ? localePath('/fast-order') : undefined"
                            class="bg-transparent py-3 px-5.5 md:px-3.5 relative group cursor-pointer"
                            :title="$t('quickOrder')"
                            :aria-label="$t('quickOrder')"
                            @click="quickOrderClicked"
                        >
                            <SvgoStopWatch class="h-6 w-auto" />
                            <span
                                class="absolute text-sm hidden lg:group-hover:block -bottom-3 left-1/2 translate-x-[-50%] whitespace-nowrap"
                            >
                                {{ $t('quickOrder') }}
                            </span>
                        </component>

                        <button
                            key="accountMenuClient"
                            data-testid="account-offcanvas-trigger"
                            class="bg-transparent py-3 px-5.5 md:px-3.5 relative group"
                            :title="$t('account.label')"
                            :aria-label="$t('account.label')"
                            @click="
                                isLoggedIn
                                    ? offcanvas.open('AccountMenu', 'layout-header-account-menu')
                                    : offcanvas.open('AccountLoginForm', 'layout-login-offcanvas')
                            "
                        >
                            <SvgoUser class="h-6 w-auto" />
                            <span
                                class="absolute text-sm hidden lg:group-hover:block -bottom-3 left-1/2 translate-x-[-50%] whitespace-nowrap"
                            >
                                {{ $t('account.label') }}
                            </span>
                            <SharedBadge
                                v-if="isLoggedIn"
                                class="absolute bg-brand-success top-1.25 right-1.25 w-4 h-3.75 !p-0.5"
                            >
                                <LazySvgoCheck class="text-white w-2.75 h-2.75" />
                            </SharedBadge>
                        </button>

                        <SharedOffcanvasContent
                            id="layout-header-account-menu"
                            :offcanvas="offcanvas"
                        />

                        <button
                            class="lg:hidden bg-transparent py-3 px-5.5 md:px-3.5"
                            aria-label="menu"
                            @click="offcanvas.open('LayoutSideMenuWrapper', 'layout-side-menu')"
                        >
                            <SvgoBars class="h-6 w-6" />
                        </button>

                        <SharedOffcanvasContent
                            id="layout-side-menu"
                            :offcanvas="offcanvas"
                        />

                        <div class="hidden lg:block">
                            <LayoutHeaderCartButton />
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

        <div
            ref="menuWrapper"
            class="relative bg-white"
            :class="{ 'pt-2.5': hideHeaderTopPart }"
        >
            <div class="container justify-between flex items-center pb-2.5 pr-0 lg:pr-5">
                <div class="hidden lg:flex lg:items-center lg:w-full">
                    <LayoutTopNavigation :menu-wrapper-height="menuWrapper ? menuWrapper.clientHeight : undefined" />
                </div>

                <LayoutStoreSearch type="mobile" />

                <div class="lg:hidden">
                    <LayoutHeaderCartButton type="mobile" />
                </div>

                <div class="shrink-0 hidden lg:flex">
                    <SharedButton
                        color="transparent"
                        size="sm"
                        :title="$t('helpAndService')"
                        :aria-label="$t('helpAndService')"
                        :link="true"
                        @click="offcanvas.open('AccountServiceMenu', 'layout-header-service-menu')"
                    >
                        <SvgoUserHeadset class="w-auto h-4 mr-2" /> {{ $t('helpAndService') }}
                    </SharedButton>

                    <SharedOffcanvasContent
                        id="layout-header-service-menu"
                        :offcanvas="offcanvas"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
